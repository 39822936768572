import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/utils";

export const TEXT_COLORS_CARD_BASE = {
  blue: "text-primary-500",
  gray: "text-primary-500",
  orange: "text-orange-600",
  yellow: "text-yellow-600",
  red: "text-status-red-600",
  green: "text-green-600",
  purple: "text-purple-600",
  brand: "text-white",
  primary: "text-primary-500",
} as const;

const cardVariants = cva(
  "pt-6 pb-4 grid gap-4 rounded-lg border text-gray-1000",
  {
    variants: {
      color: {
        white: "bg-white border-gray-300",
        blue: `bg-primary-100 border-primary-300 ${TEXT_COLORS_CARD_BASE["blue"]}`,
        gray: `bg-gray-200 border-gray-300 ${TEXT_COLORS_CARD_BASE["blue"]}`,
        orange: `bg-orange-100 border-orange-200 [&_a]:${TEXT_COLORS_CARD_BASE["orange"]} ${TEXT_COLORS_CARD_BASE["orange"]}`,
        yellow: `bg-yellow-100 border-yellow-200 [&_a]:${TEXT_COLORS_CARD_BASE["yellow"]} ${TEXT_COLORS_CARD_BASE["yellow"]}`,
        red: `bg-status-red-100 border-status-red-200 [&_a]:${TEXT_COLORS_CARD_BASE["red"]} ${TEXT_COLORS_CARD_BASE["red"]}`,
        green: `bg-green-100 border-green-200 [&_a]:${TEXT_COLORS_CARD_BASE["green"]} ${TEXT_COLORS_CARD_BASE["green"]}`,
        purple: `bg-purple-100 border-purple-600 [&_a]:${TEXT_COLORS_CARD_BASE["purple"]} ${TEXT_COLORS_CARD_BASE["purple"]}`,
        brand: `bg-brand [&_a]:${TEXT_COLORS_CARD_BASE["brand"]} ${TEXT_COLORS_CARD_BASE["brand"]}`,
      },
    },
    defaultVariants: {
      color: "gray",
    },
  },
);

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>
>(({ className, color, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(cardVariants({ color }), className)}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "text-lg px-6 font-semibold flex flex-col space-y-1.5",
      className,
    )}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardHeaderIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>
>(({ color, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-lg h-12 w-12 flex items-center justify-center",
      {
        "bg-orange-200 text-orange-600": color === "orange",
        "bg-primary-200 text-primary-500": color === "blue" || color === "gray",
      },
      className,
    )}
    {...props}
  />
));
CardHeaderIcon.displayName = "CardTitle";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn("text-sm", className)} {...props} />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("px-6 pt-0 text-gray-1000", className)}
    {...props}
  />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-2 border-t border-[inherit]", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardHeader,
  CardHeaderIcon,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
  cardVariants,
};
